.contact {
  // Avoid images that come out of the div to modify the size of the viewport
  overflow: hidden;
  // so the child divs can occupy all the heigh of the parent div
  display: flex;
  flex-direction: column;
  position: relative;
  height: 2000px;
}
.citation {
  max-width: 100%;
  position: relative;
}
.citation img {
  display: block;
}
.citation__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--bg);
  font-family: var(--title);
  text-align: center;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 974px;
  height: 129px;
}
.contact__content {
  position: relative;
  z-index: 1;
  height: 100%;

  &--paint {
    position: absolute;
    // top: -250px;
    left: 0;
    z-index: 0;
    flex-shrink: 0;
  }
  &--leaf1 {
    position: relative;
    z-index: 1;
  }

  &--leaf2 {
    position: absolute;
    bottom: 300px;
    right: 0px;
    z-index: 1;
  }

  &--title {
    font-family: var(--title);
    position: relative;
    top: -450px;
    color: #000;
    text-align: center;
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 9;
  }
  &--text {
    display: flex;
    // to make them align at the bottom
    align-items: flex-end;
    justify-content: center;
    position: relative;
    top: -450px;
    z-index: 9;
    gap: 26px;
    margin: 142px 117px 0px 189px;

    &-paragraph {
      color: #000;
      text-align: justify;
      font-size: 20px;
      font-weight: 400;
      max-width: 868px;
    }
  }
}
.contact__footer {
  position: relative;
  top: -220px;
  margin: 0 auto;
  text-align: center;

  &--logos {
    margin-top: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 52px;
    fill: var(--green-4);
    transition: all 200ms ease-in-out;
  }
}
.contact__footer--email a {
  text-decoration: none;
  color: var(--green-4);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 150ms ease-in-out;
  &:hover {
    color: var(--green-2);
    cursor: text;
  }
}
.contact__footer--logos a {
  transition: all 150ms ease-in-out;
  :hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}
.signature {
  font-family: monospace;
  font-weight: normal;
  text-align: right;
  position: relative;
  top: 50px;
  padding-right: 10px;
  a {
    color: var(--green-2);
    text-decoration: none;
    transition: all 150ms ease-in-out;
    &:hover {
      color: var(--green-4);
      cursor: pointer;
    }
  }
}

// Move paint stroke when the screen is bigger than 2150px
@media (min-width: 2150px) {
  .contact {
    height: 2300px;
  }
  .contact__content--paint {
    top: -250px;
  }
}
// Responsive ipad
@media (max-width: 1200px) {
  .contact__content {
    &--paint {
      top: 50px;
    }
    &--leaf1 {
      z-index: 0;
      top: -190px;
      rotate: 90deg;
      left: 750px;
      opacity: 0.5;
      max-width: 40%;
    }

    &--leaf2 {
      bottom: 400px;
      right: 700px;
      // flip the image
      transform: scalex(-1);
      opacity: 0.5;
      max-width: 50%;
      rotate: -40deg;
    }

    &--title {
      top: -600px;
      font-size: 45px;
    }
    &--text {
      // to make the text and the image responsives
      flex: column;
      flex-wrap: wrap;
      top: -550px;
      z-index: 9;
      margin: 0 30px;
    }
  }
}

// smaller ipads in vertical
@media (max-width: 930px) {
  .citation__text {
    font-size: 40px;
    top: 50%;
    max-width: 90%;
  }
  .contact__content {
    &--paint {
      top: 150px;
    }
    &--leaf1 {
      top: -130px;
      left: 500px;
    }
    &--leaf2 {
      bottom: 350px;
      right: 500px;
    }

    &--title {
      top: -450px;
    }
    &--text {
      top: -400px;
    }
  }

  // Responsive phone
  @media (max-width: 576px) {
    .contact {
      height: fit-content;
    }
    .citation__text {
      font-size: 25px;
      top: 40%;
      max-width: 90%;
    }
    .citation {
      height: 238px;
    }
    .contact__content {
      &--paint {
        top: 450px;
        width: 866px;
        height: 515px;
        left: -200px;
      }
      &--leaf1 {
        top: -190px;

        left: 210px;

        max-width: 50%;
      }

      &--leaf2 {
        bottom: 250px;
        right: 200px;
      }

      &--title {
        top: -370px;
      }
      &--text {
        top: -350px;
      }
    }

    .contact__footer--email a {
      font-size: 25px;
    }

    .signature {
      font-size: 12px;
    }
  }
  // Responsive smaller phone
  @media (max-width: 370px) {
    .citation__text {
      font-size: 20px;
      top: 40%;
      max-width: 95%;
    }
    .contact__content--text {
      margin: 0 10px;
    }
    .contact__footer--email a {
      font-size: 20px;
    }
    .contact__footer--logos img {
      width: 50px;
    }
    .contact__footer--logos {
      margin-top: 30px;
    }
  }
}
