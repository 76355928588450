.NavigationBar {
  max-width: 100%;
  font-family: var(--navbar);
  padding: 0px 45px;
  background-color: var(--bg);
  font-size: 20px;
  font-style: normal;
}

// responsive ipad & phone
@media (max-width: 900px) {
  .NavigationBar {
    padding: 0px 20px;
  }
}
