.Footer {
  //   overflow: hidden;
  margin-bottom: 100px;
  &__content {
    display: flex;
    justify-content: center;
    gap: 40px;
    &--logo img {
      width: 256px;
      height: 256px;
      flex-shrink: 0;
    }
  }
  &__title {
    font-family: var(--title);
    color: #000;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__list {
    color: var(--green-4);
    font-family: var(--text);
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    img {
      margin-right: 29px;
      width: 29px;
    }
  }
  ul {
    margin: 0 auto;
    list-style-type: none;
    padding-left: 155px;
  }
  &__logos {
    margin-top: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 52px;
    fill: var(--green-4);
    transition: all 200ms ease-in-out;
    a {
      transition: all 150ms ease-in-out;
      :hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
  &__signature {
    font-family: monospace;
    font-weight: normal;
    text-align: right;
    position: relative;
    top: 50px;
    padding-right: 10px;
    a {
      color: var(--green-2);
      text-decoration: none;
      transition: all 150ms ease-in-out;
      &:hover {
        color: var(--green-4);
        cursor: pointer;
      }
    }
  }
}

// Responsiveness

// For bigger screens is good
// For bigger tablet horizontal is also good
// For smaller tablet horizontal
@media (max-width: 1200px) {
  .Footer {
    &__content {
      gap: 10px;
      &--logo img {
        width: 230px;
        height: 230px;
      }
    }

    &__title {
      font-size: 70px;
    }
    ul {
      padding-left: 130px;
    }
  }
}

// smaller tablet when vertical
@media (max-width: 930px) {
  .Footer {
    &__content {
      flex-direction: column;
      text-align: center;
    }

    &__list {
      img {
        display: none;
      }
    }
    ul {
      padding: 0px;
    }
  }
}

// responsive phone

@media (max-width: 576px) {
  .Footer {
    &__content {
      gap: 20px;
      position: relative;

      &--logo {
        img {
          width: 195px;
          height: 195px;
        }
      }
    }
    &__title {
      font-size: 60px;
    }
    &__list {
      font-size: 25px;
    }
    &__logos {
      margin-top: 10px;
    }
    &__signature {
      font-size: 12px;
    }
  }
}

// responsive phone

@media (max-width: 370px) {
  .Footer {
    &__content {
      &--logo {
        img {
          width: 170px;
          height: 170px;
        }
      }
    }
    &__title {
      font-size: 50px;
    }
    &__list {
      font-size: 20px;
    }
    &__logos {
      img {
        width: 50px;
      }
    }
  }
}
