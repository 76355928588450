.About {
  // you need this to make the navbar functional
  position: relative;
  // Avoid images that come out of the div to modify the size of the viewport
  overflow: hidden;
  //   To compensate for the relative position of the child divs in negative top
  height: 2100px;

  &__paint {
    position: absolute;
    // top: 340px;
    top: -80px;
    left: 0;
    z-index: 0;
    flex-shrink: 0;
    // opacity: 0.5;
  }
  &__leaf1 {
    position: relative;
    z-index: 1;
    top: 50px;
    float: right;
    width: 400px;
  }

  &__leaf2 {
    position: relative;
    width: 326px;
    top: 350px;
    z-index: 1;
  }
  &__title {
    font-family: var(--title);
    position: relative;
    top: -500px;
    margin-left: 53px;
    color: #000;
    text-align: left;
    font-size: 90px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    z-index: 9;
    width: 652px;
  }
  &__metanoia {
    margin: 0 auto;
    position: relative;
    top: -300px;
    z-index: 9;
    color: #000;
    width: 900px;
    height: 415px;
    text-align: center;
    font-family: var(--text);
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  &__eucalipto {
    width: 100%;
    position: relative;
    z-index: 0;
    top: -250px;
    &--content {
      position: relative;
      color: var(--bg);
      top: -750px;
    }
    &--text {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 auto;
      max-width: 1073px;
      margin-top: 64px;
    }
    &--list {
      display: flex;
      margin-top: 48px;
      justify-content: space-between;
    }
  }
  &__subtitle {
    font-family: var(--text);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 103px;
  }
  &__suite {
    position: relative;
    top: -450px;
    &--text {
      font-size: 20px;
      display: flex;
      margin: 0 auto;
      flex-shrink: 0;
      gap: 200px;
      p {
        margin-left: 218px;
        margin-top: 64px;
        width: 772px;
        height: 240px;
      }
      img {
        position: relative;
        top: -250px;
      }
    }
  }
}

// For bigger screens
@media (min-width: 2150px) {
  .About {
    height: 2500px;
    &__paint {
      max-width: 80%;

      top: -200px;
      left: 300px;
    }
    &__subtitle {
      margin-left: 500px;
    }
    &__eucalipto--content {
      top: -850px;
    }
    &__suite--text {
      gap: 300px;
      p {
        margin-left: 600px;
      }
    }
  }
}

// bigger tablet horizontal
@media (max-width: 1400px) {
  .About {
    height: 1900px;
    &__paint {
      top: -50px;
    }

    &__leaf1 {
      width: 250px;
    }

    &__leaf2 {
      width: 200px;
      top: 230px;
    }
    &__title {
      top: -300px;
    }
    &__metanoia {
      top: -200px;
    }
    &__eucalipto--content {
      top: -650px;
    }
  }
}

// responsive ipad
@media (max-width: 1200px) {
  .About {
    height: 1600px;
    &__paint {
      top: 30px;
    }
    &__title {
      font-size: 70px;

      width: 400px;
    }
    &__metanoia {
      font-size: 30px;
      width: 700px;
    }

    &__eucalipto {
      &--content {
        top: -580px;
      }
      &--text {
        font-size: 16px;
        max-width: 700px;
        margin-top: 50px;
      }
      &--list {
        display: flex;
        margin-top: 48px;
        justify-content: space-between;
      }
    }
    &__subtitle {
      font-family: var(--text);
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 103px;
    }

    &__suite {
      top: -450px;
      &--text {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        p {
          position: relative;
          z-index: 9;
          margin: 0 auto;
          margin-top: 40px;
          max-width: 90%;
          text-align: justify;
        }
        img {
          position: relative;
          z-index: 0;
          top: -400px;
          width: 300px;
          opacity: 0.7;
          float: right;
          left: 30px;
        }
      }
    }
  }
}

// smaller tablets in vertical
@media (max-width: 930px) {
  .About {
    height: 1250px;
    // Need to make position of container relative so overflow hidden works
    position: relative;
    overflow: hidden;
    &__paint {
      top: 0px;
    }
    &__title {
      font-size: 50px;
      width: 326px;
      top: -170px;
    }
    &__leaf1 {
      top: 120px;
      float: right;
      left: 30px;
      width: 130px;
    }

    &__leaf2 {
      width: 113px;
      top: 250px;
      right: 10px;
      z-index: 1;
    }
    &__metanoia {
      top: -70px;
      width: 600px;
      height: 197px;
      font-size: 25px;
    }
    &__eucalipto {
      top: 0px;
      &--content {
        top: -260px;
      }
      &--text {
        margin-top: 20px;
      }
      &--list {
        margin-top: 20px;
      }
    }

    &__suite {
      top: -200px;
    }
    &__subtitle {
      margin-top: 10px;
      margin-left: 0px;
      text-align: center;
    }
  }
}

// responsive phone

@media (max-width: 576px) {
  .About {
    height: 1600px;

    &__paint {
      width: 890px;
      height: 600px;
      left: -200px;
      top: 0px;
    }
    &__title {
      margin: 0 auto;
      text-align: center;
    }
    &__leaf1 {
      left: 40px;
    }

    &__leaf2 {
      right: 30px;
    }
    &__metanoia {
      top: -100px;
      width: 292px;
    }
    &__eucalipto {
      top: 0px;
      // change the image from About.js
      content: url(../../img/decor/eucaliptophone.png);
      width: 100%;
      height: 614px;
      &--content {
        top: -580px;
      }
      &--text {
        margin: 40px 18px 0px 18px;
        text-align: justify;
        p {
          margin-bottom: 0px;
        }
      }
      &--list {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-content: stretch;
        text-align: center;
        margin-top: 0px;
        ul {
          margin: 0 auto;
          list-style-type: none;
          padding: 0;
        }
      }
    }
    &__suite {
      top: -400px;
      &--text {
        img {
          top: -100px;
          width: 146.474px;
          height: 227.921px;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .About {
    height: 1800px;
    &__suite {
      img {
        top: 0px;
      }
    }
  }
}
// responsive smaller phone
@media (max-width: 370px) {
  .About {
    height: 1700px;
    &__title {
      max-width: 100%;
      font-size: 40px;
    }
    &__metanoia {
      font-size: 20px;
      top: -60px;
    }
    &__subtitle {
      font-size: 25px;
    }
    &__eucalipto {
      top: -25px;
      &--text {
        font-size: 16px;
      }
    }
    &__suite {
      top: -500px;
    }
  }
}
