$color: var(--green-2);
.Hero {
  height: 97vh;
  background: url("../../img/carousel/womanmountain.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;

  &__content {
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--title {
      font-family: var(--title);
      font-size: 130px;
      max-width: 817px;
      color: black;
      opacity: 0.85;
    }
    &--btns {
      display: flex;
      justify-content: space-between;
      padding-right: 100px;
    }
    &--logo {
      width: 390px;
      height: 390px;
    }
  }
}
.cta-btn {
  width: 326px;
  height: 91px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--bg);
  opacity: 0.75;
  color: 0000;
  text-align: center;
  font-family: var(--navbar);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: var(--green-3);
    color: var(--bg);
  }
}

// Responsive ipad
@media (max-width: 1200px) {
  .Hero__content {
    &--logo {
      width: 250px;
      height: 250px;
    }
    &--title {
      font-size: 80px;
      max-width: 400px;
    }
  }
  .cta-btn {
    width: 138px;
    height: 56px;
    font-size: 16px;
  }
}

// Responsive phone
@media (max-width: 576px) {
  .Hero {
    max-height: 100%;
    width: 100%;
  }
  .Hero__content {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
    text-align: center;
    &--logo {
      width: 195px;
      height: 195px;
    }
    &--title {
      font-size: 60px;
      max-width: 330px;
    }
    &--btns {
      display: flex;
      justify-content: space-evenly;
      padding-right: 0px;
    }
  }
}
