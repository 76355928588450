.Reiki {
  position: relative;
  overflow: hidden;
  &__circle {
    float: right;
    width: 393px;
    // margin-left: 130px;
    // top: 240px;
    // position: absolute;
    // z-index: -1;
  }
  &__leaf1 {
    position: absolute;
    top: 150px;
    left: 20px;
    z-index: 0;
  }
  &__leaf2 {
    position: absolute;
    top: 180px;
    text-align: right;
    right: 0%;
    z-index: 2;
  }
  &__title {
    font-family: var(--title);
    color: #000;
    text-align: left;
    font-size: 90px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    z-index: 9;
    margin-left: 140px;
    margin-top: 70px;
  }
  &__subtitle {
    font-size: 30px;
    font-weight: 700;
  }
  &__intro {
    display: flex;
    justify-content: space-between;
    margin-left: 140px;
    margin-top: 40px;

    &--text {
      text-align: left;
      max-width: 57%;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__text {
    margin: 58px 0px 58px 0px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__intro2 {
    margin-left: 140px;
  }
  &__usui {
    flex: none;
    margin-right: 100px;
    position: relative;
    max-height: 393px;
    &--content {
      position: absolute;
      z-index: 10;
      display: flex;
      flex-direction: column;
      //   center elements in the carousel
      top: 57%;
      left: 40%;
      margin: -72px -1000px -1000px -72px;
      font-size: 30px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
    }
    &--carousel {
      align-self: center;
      max-width: 270px;
      text-align: center;
      margin-left: -10px;
    }
  }
  &__poppy {
    width: 100%;
    margin-top: 240px;

    position: relative;
    z-index: -10;
    img {
      width: 100%;
      position: absolute;
      z-index: -1;
    }
    &--content {
      color: var(--bg);
      display: flex;
      gap: 86px;
      padding-left: 150px;
      padding-right: 140px;
      img {
        position: relative;
        top: -150px;
        max-width: 650px;
      }
    }
    &--text {
      margin-top: 94px;
      text-align: justify;
    }
    &--price {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
    }
  }
  &__price {
    font-size: 30px;
    font-weight: 500px;
  }
}
// For bigger screens
@media (min-width: 2150px) {
  .Reiki {
    &__intro {
      &--text {
        max-width: 70%;
      }
    }
    &__poppy {
      &--content {
        padding: 0px 300px 0 100px;
        gap: 300px;
        img {
          top: -200px;
        }
      }
      &--text {
        margin-top: 300px;
      }
    }
  }
}

// For bigger tablet horizontal screens nothing is needed

// For tablet horizontal screens
@media (max-width: 1200px) {
  .Reiki {
    &__leaf1 {
      right: 100px;
    }

    &__leaf2 {
      top: 500px;

      z-index: 1;
    }
    &__title {
      margin-left: 50px;
    }
    &__intro {
      margin-left: 50px;
      flex-direction: column;
      z-index: 10;
      &--text {
        max-width: 90%;
      }
    }
    &__intro2 {
      margin-left: 50px;
      max-width: 90%;
    }
    &__poppy {
      margin-top: 150px;
      margin-bottom: 100px;
      img {
        height: 420px;
      }
      &--content {
        gap: 30px;
        padding-right: 100px;
        padding-left: 50px;
        img {
          top: -50px;
          width: 300px;
        }
      }
      &--text {
        margin-top: 50px;
      }
    }
  }
}

// smaller tablet when vertical

@media (max-width: 930px) {
  .Reiki {
    &__leaf2 {
      top: 600px;
      opacity: 0.7;
    }
    &__title {
      margin: 0 auto;
      font-size: 50px;
      margin-top: 30px;
      text-align: center;
    }
    &__intro {
      margin-top: 0px;
      &--text {
        text-align: center;
      }
    }
    &__intro2 {
      text-align: center;
    }
    &__poppy {
      margin-top: 150px;
      img {
        height: auto;
      }
      &--img {
        content: url(../../img/decor/poppyvertab.png);
      }
      &--content {
        padding-left: 50px;
        padding-right: 50px;
        img {
          top: -50px;
          width: 200px;
        }
      }
    }
  }
}

// responsive phone

@media (max-width: 576px) {
  .Reiki {
    &__leaf1 {
      display: none;
    }
    &__leaf2 {
      width: 40%;
      top: 800px;
    }
    &__title {
      margin: 0 auto;
      font-size: 50px;
      margin-top: 30px;
      text-align: center;
    }
    &__intro {
      margin: 0 auto;
      padding: 0px 18px 0px 18px;

      &--text {
        max-width: 100%;
        text-align: justify;
        font-size: 16px;
      }
    }
    &__intro2 {
      margin: 0 auto;
      padding: 0px;
      text-align: justify;
      font-size: 16px;
    }
    &__usui {
      margin-right: 0px;
      &--content {
        //   center elements in the carousel
        left: 35%;
      }
    }
    &__poppy {
      margin-top: 150px;
      img {
        height: auto;
      }
      &--img {
        content: url(../../img/decor/poppymob.png);
      }
      &--text {
        margin-top: 0px;
        position: relative;
        top: -40px;
      }
      &--content {
        flex-direction: column;
        padding-left: 18px;
        padding-right: 18px;
        gap: 10px;
        img {
          top: -50px;
          width: 296px;
          content: url(../../img/decor/ReikiPicMob.png);
          margin: 0 auto;
        }
      }
    }
    &__text {
      margin-top: 20px;
      font-size: 16px;
    }
  }
}

// responsive smaller phone
@media (max-width: 370px) {
  .Reiki {
    &__leaf2 {
      top: 900px;
      opacity: 0.7;
    }
    &__title {
      font-size: 40px;
    }

    &__subtitle {
      font-size: 25px;
    }
    &__usui {
      margin-right: 0px;
      &--content {
        //   center elements in the carousel
        left: 32%;
      }
    }
    &__poppy {
      margin-top: 50px;
      &--text {
        top: 0px;
      }
      &--content {
        padding: 20px 30px 0 30px;
        flex-direction: column-reverse;
        img {
          width: 250px;
        }
      }
    }
  }
}
