.Hypno {
  position: relative;
  overflow-x: hidden;
  &__circle {
    margin-left: 130px;
    top: 240px;
    position: absolute;
    z-index: -1;
  }
  &__leaf1 {
    position: absolute;
    top: 130px;
    left: 20px;
    z-index: 0;
  }
  &__title {
    font-family: var(--title);
    color: #000;
    text-align: right;
    font-size: 90px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    z-index: 9;
    margin-right: 96px;
    margin-top: 70px;
  }
  &__intro {
    text-align: right;
    margin-right: 96px;
    margin-top: 65px;
    padding-left: 350px;
  }
  &__text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 50px 0px 50px 0px;
  }
  &__subtitle {
    font-size: 30px;
    font-weight: 700;
  }
  &__aeros {
    margin-top: 240px;

    width: 100%;
    position: relative;
    z-index: -10;
    img {
      width: 100%;
      position: absolute;
      z-index: -1;
    }
    &--content {
      color: var(--bg);
      display: flex;
      gap: 60px;
      padding-left: 150px;
      padding-right: 96px;
      img {
        position: relative;
        top: -200px;
      }
    }
    &--text {
      margin-top: 94px;
      text-align: justify;
    }
    &--price {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
    }
  }
  &__price {
    font-size: 30px;
    font-weight: 500px;
  }
}

// For bigger screens
@media (min-width: 2150px) {
  .Hypno {
    &__circle {
      top: 200px;
    }
    &__leaf1 {
      top: 90px;
    }
    &__aeros {
      &--content {
        padding: 0px 100px 0 300px;
        gap: 300px;
      }
      &--text {
        margin-top: 300px;
      }
    }
    &__intro {
      padding-left: 700px;
    }
  }
}

// For bigger tablet horizontal screens nothing is needed

// For tablet horizontal screens
@media (max-width: 1200px) {
  .Hypno {
    &__circle {
      max-width: 30%;
    }
    &__leaf1 {
      max-width: 40%;
    }
    &__title {
      margin-right: 50px;
    }
    &__intro {
      margin-right: 50px;
      padding-left: 250px;
    }
    &__aeros {
      margin-top: 150px;
      margin-bottom: 100px;
      img {
        height: 420px;
      }
      &--content {
        gap: 30px;
        padding-left: 100px;
        padding-right: 50px;
        img {
          top: -50px;
          width: 300px;
        }
      }
      &--text {
        margin-top: 50px;
      }
    }
  }
}

// smaller tablet when vertical

@media (max-width: 930px) {
  .Hypno {
    &__circle {
      opacity: 0.5;
    }
    &__leaf1 {
      opacity: 0.2;
    }
    &__title {
      margin: 0 auto;
      font-size: 50px;
      margin-top: 30px;
      text-align: center;
    }
    &__intro {
      margin-right: 50px;
      padding-left: 50px;
      text-align: center;
    }
    &__aeros {
      margin-top: 150px;
      img {
        height: auto;
      }
      &--img {
        content: url(../../img/decor/aerosvertab.png);
      }
      &--content {
        padding-left: 50px;
        padding-right: 50px;
        img {
          top: -50px;
          width: 200px;
        }
      }
    }
    &__text {
      margin-top: 20px;
      font-size: 16px;
    }
  }
}

// responsive phone

@media (max-width: 576px) {
  .Hypno {
    &__circle {
      opacity: 1;
      max-width: 75%;
      top: 100px;
      left: -200px;
    }
    &__leaf1 {
      opacity: 0;
    }
    &__title {
      margin: 0 auto;
      font-size: 50px;
      margin-top: 30px;
      text-align: center;
    }
    &__intro {
      margin-right: 18px;
      padding-left: 18px;
      text-align: justify;
    }
    &__aeros {
      margin-top: 150px;
      img {
        height: auto;
      }
      &--img {
        content: url(../../img/decor/aerosmob.png);
      }
      &--text {
        margin-top: 0px;
      }
      &--content {
        flex-direction: column-reverse;
        padding-left: 18px;
        padding-right: 18px;
        gap: 10px;
        img {
          top: -50px;
          width: 296px;
          content: url(../../img/decor/HypnoPicMob.png);
          margin: 0 auto;
        }
      }
    }
    &__text {
      margin-top: 20px;
      font-size: 16px;
    }
  }
}

// responsive smaller phone
@media (max-width: 370px) {
  .Hypno {
    &__title {
      font-size: 40px;
    }

    &__subtitle {
      font-size: 25px;
    }
    &__aeros {
      margin-top: 50px;
      &--content {
        padding: 20px 30px 0 30px;
        flex-direction: column;
        img {
          width: 250px;
        }
      }
    }
  }
}
